.article {
  border: 1px solid rgba(255, 255, 255, 0.221);
}
/*
.specialsquare{
    margin-top:10px;

}  */
@media (min-width: 320px) and (max-width: 480px) {
  .article {
    font-size: 15px !important;
  }
}

