span {
  text-align: center;
}
/* *{
    border: 1px solid wheat;
  } */
.blue {
  color: #3992ff;
}
.test {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.ieeeText {
  bottom: -05%;
  font-size: 17rem;
  left: -01%;
}
.sphere {
  width: 80%;
  right: 10%;
  top: -40%;
}
.line {
  width: 70%;
  height: 2px;
  border-color: white;
  background-color: white;
  margin: auto;
}
.card:hover {
  transform: scale(1.1);
  transition: ease 0.5s all;
  /* box-shadow: #7834ff 0 0 1px ; */
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2;
  border: 0 0 2 0;
  background-color: #171717;
}
.black {
  background-color: #121316;
}
.card {
  width: 300px;
  height: 400px;
  background-color: #101010da;
  position: relative;
  backdrop-filter: blur(10px);
  text-align: center;
  margin-bottom: 20px;
}
.cardHeader {
  background-color: #3992ff;
  height: 40%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background-color: #045de9;
  background-image: linear-gradient(315deg, #045de9 0%, #09c6f9 74%);
}
.icon:hover {
  opacity: 0.5;
}
.icon {
  margin-top: 20px;
}
.cardFooter {
  border-top: 1px solid rgba(255, 255, 255, 0.051);
}
.committeeContainer {
  width: 100vw;
}
.commiteeCard {
  background: transparent;
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.23);
  width: 300px;
  height: 300px;
}
.commiteeCard:hover {
  box-shadow: 0 18px 18px rgba(0, 0, 0, 0.253);
  border: 1px solid white;
}
.boardContainer {
  background-color: #121316 !important;
}
.ourMission,
.ourVision {
  background-image: url("../../Images/IEEEBackImage.png");
  background-size: cover;
  background-position: center center;
}

@media (min-width: 1065px) and (max-width: 1555px) {
  .textShadowDark {
    font-size: 6rem;
  }
  .whatWeDoSection div {
    margin-top: 30px;
  }
  .textShadowLight {
    font-size: 7rem;
  }
  .IEEETextShadowSection2 {
    bottom: -30%;
    left: -10%;
    font-size: 18rem;
  }
}

@media (min-width: 1025px) and (max-width: 1064px) {
}

@media (min-width: 768px) and (max-width: 1024px) {
  /* CSS */
}
@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  /* CSS */
}
@media (min-width: 481px) and (max-width: 767px) {
  .heading {
    font-size: 5rem;
    width: 100%;
  }
  * {
    padding: 0;
  }
  .commiteeCard {
    width: 200px;
    height: 200px;
  }
}
