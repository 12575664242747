@import url("https://fonts.googleapis.com/css2?family=Inter&family=Libre+Caslon+Text:wght@700&family=Major+Mono+Display&family=Montserrat:ital,wght@0,100;1,300&family=Open+Sans:wght@300&family=Oswald:wght@200;400&family=Raleway:wght@100&family=Roboto:wght@100&family=Source+Sans+Pro:wght@600&family=ZCOOL+QingKe+HuangYou&display=swap");

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-Light.ttf);
  font-weight: 300;
}

@font-face {
  font-family: "Exo";
  src: url(../../fonts/Exo-VariableFont_wght.ttf);
}

* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
  box-sizing: border-box;
}
html {
  background-color: #121316;
}
body {
  overflow-x: hidden;
  padding: 0;
  margin: 0;
}
::-webkit-scrollbar {
  width: 4px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #121316;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
  border-radius: 10px;
}
.whatWeDoSection {
  height: 100vh;
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}
.textShadowLight {
  color: rgb(46, 46, 46);
  opacity: 0.05;
}
.textShadowDark {
  color: rgb(255, 255, 255);
  opacity: 0.05;
}
.IEEEShadowHeaderBottom {
  font-size: 20rem !important;
}
.IEEEShadowHeaderTop {
  font-size: 20rem !important;
}
.WhoWeAreSection {
  height: 100vh;
  background-image: url("../../Images/IEEEBackImage.png");
  background-size: cover;
  background-position: center;
  box-shadow: 0 18px 18px black;
}
.whoWeAreTitle {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
}
.doughnut {
  left: -25%;
  top: 130%;
  z-index: 1000;
}

* {
  font-family: "Poppins", sans-serif;
}
*::selection {
  background-color: #9a9a9a54;
}
.partnerImage {
  width: 100%;
  margin: auto;
}
.cardHeader {
  width: 70%;
}
.header {
  height: 100vh;
  width: 100vw !important;
}
.partnersCard {
  margin-right: 20px;
  background-color: #00000063;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 250px;
  height: 300px;
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.221);
}
.partnersCard:hover {
  transition: 0.3s ease all;
  transform: scale(1.1);
  cursor: pointer;
}
.blue {
  color: "#3992FF" !important;
}
.IEEETextShadowSection2 {
  bottom: -66%;
  left: -12%;
  font-size: 20rem;
}
main {
  width: 100vw;
  height: 100vh;
}
.shadow {
  box-shadow: 0 18px 18px rgb(0, 0, 0) !important;
  border-right: 1px solid black !important;
}
.joinUsButton {
  background-image: linear-gradient(92.88deg, #3992ff 19.16%, #3f62d7 64.72%);
  border-radius: 8px;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  margin-top: 30px;
  text-shadow: rgba(0, 0, 0, 0.25) 0 3px 8px;
  transition: all 0.5s;
}
.joinUsButton:hover {
  transform: scale(1.1, 1.1);
  box-shadow: 0 18px 18px rgba(0, 0, 0, 0.247);
}
@media screen and (max-width: 500px) {
  .partnersCard {
    width: 200px;
    height: 250px;
  }
}
