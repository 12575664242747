* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.table-wrapper td {
  border: 1px solid rgba(255, 255, 255, 0.064);
}
.fontSizeZ{
  font-size: 2.5rem;
}
