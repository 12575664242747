/* * {
    background-color: rgba(255, 0, 0, 0.11);
} */

.stepTitle {
  color: white;
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 1.5em;
}
.stepSubTitle {
  margin-top: 0.7em;
  font-size: 1.5rem;
}
.stepSubTitle::after {
  content: " *";
  color: red;
  font-size: 90%;
}
.fieldContainer {
  display: flex;
  flex-direction: column;
  padding-bottom: 1.2em;
}
.fieldLabel::after {
  content: " *";
  color: red;
  font-weight: bold;
}
label.optional::after {
  content: "";
}

/* ------------------------------------- steps buttons styling  */
.primaryButton {
  background-color: #3992ff;
  color: white;
  border-radius: 4em;
  padding: 0.6em 2em;
}
.secondaryButton {
  color: #a3a3a3;
}

/* ------------------------------------- text field, text box and drop down items styling  */
.inputField {
  border: #a3a3a3 0.1em solid;
  border-radius: 0.4em;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0.3em 0.4em;
}
.invalidInputField {
  /* border: #fbbebe 0.1em solid; */
  border: red 0.1em solid;
  border-radius: 0.4em;
  outline: none;
  background-color: transparent;
  box-sizing: border-box;
  padding: 0.3em 0.4em;
}
/* .inputField:invalid {
    border: rgb(99, 31, 31) solid 0.1em;
}
.inputField:invalid:hover {
    border: rgb(251, 190, 190) solid 0.1em;
} */
.inputField:focus,
.inputField:hover {
  border: #3992ff 0.1em solid;
}
.dropDownItems {
  color: white;
  background-color: #121316;
}

/* ------------------------------------- radio button styling */
.radioSection {
  display: flex;
  flex-direction: column;
  display: relative;
}
.radioItemInput {
  display: none;
  position: relative;
}
.radioItemInput:checked ~ .radioItemLabel::before {
  outline: #3992ff 0.1em solid;
  background-color: #3992ff;
}
.radioItemInput:checked + .radioItemLabel::after {
  background-color: #3992ff;
  outline: border;
  display: inline;
}
.radioItemLabel {
  position: relative;
  padding-left: 1.3em;
  cursor: pointer;
}
.radioItemLabel::before {
  content: "";
  width: 0.9em;
  height: 0.9em;
  position: absolute;
  left: -0.02em;
  top: 0.3em;
  background-color: transparent;
  border: #121316 0.2em solid;
  outline: #a3a3a3 0.1em solid;
  border-radius: 50%;
}
.radioItemLabel:hover::before {
  outline: #3992ff 0.1em solid;
}

/* ------------------------------------- radio buttons group styling */
.radioButtonsGroupContainerLabel {
  display: flex;
  justify-content: space-between;
  font-weight: 100;
  color: #a3a3a3;
}
.radioButtonsGroupContainer {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-around;
  gap: 0.2em;
}
.radioButtonsGroupItem {
  flex-grow: 1;
  background-color: #a3a3a3;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.radioButtonsGroupItem:hover {
  background-color: #3992ff;
}
.radioButtonsGroupInput {
  display: none;
}
.radioButtonsGroupInput:checked + .radioButtonsGroupLabel {
  background-color: #3992ff;
}
.radioButtonsGroupLabel {
  text-align: center;
  flex-grow: 1;
}
/*
step four styling trial
.radioButtonsGroupItem:hover ~ .radioButtonsGroupItem {
    background-color: #3992ff;
} */

.validationErrorMessage {
  padding: 0.5em 0.5em;
  margin: 1em 0;
  background-color: rgb(64, 20, 20);
  color: #fbbebe;
  border-radius: 0.5em;
}

.popupText {
  color: white;
}
