@media only screen and (max-width: 480px ) {
    
     /* .title{
        position: relative;
        right:-4px;
     } */
     /* .articlebk{
        position: absolute;
       bottom: 180px;
        right: 117px;
     } */
     /* .rel{
        position: relative;
     } */
    
  }
  @media (max-width: 1076px){
    .flexcontrol{
         justify-content: center;
    }
    .title{
        text-align: center;
    }

  }
  @media (max-width:480px){
    .buttoncontroller{
        position: relative;
    }
    .buttonmove{
         
        position: absolute;
        left: 11px;
         bottom: 602px;
    }
  }
