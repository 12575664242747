@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-Bold.ttf);
  font-weight: 700;
}

.cardcontainer {
  background-color: #000 !important;
}
.cardquote {
  background-color: #3992ff !important;
}
.cardcontainer {
  color: #fff !important;
  margin-bottom: 30px;
}
.cardcontainer {
  width: 100%;
  height: 550px;
}

.title {
  font-family: "Poppins" !important;
  color: #3992ff;
  font-size: 25px !important;
}

.description {
  font-weight: 300 !important;
  font-size: 16px;
}

.year {
  font-family: "Poppins" !important;
  font-size: 10px !important;
}

.quote {
  font-family: "Poppins" !important;
  font-size: 36px !important;
}

.noquote {
  display: none !important;
}

.break {
  border: 1px solid white;
  opacity: 0.2 !important;
}

.alert {
  position: absolute;
  left: 300%;
  bottom: 50%;
  background-color: black !important;
  color: #3992ff !important;
  font-size: 16px !important;
}

/* .cardcontainer :hover{
    box-shadow: 0 18px 18px #000;
    transform: scale(1.1)
} */
