@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-Bold.ttf);
  font-weight: 700;
}

* {
  font-family: "Poppins" !important;
}

.title {
  font-size: 24px;
  font-weight: bold;
  color: #fff;
}

div > p {
  font-weight: 700;
  margin-bottom: 20px;
  color: white;
}

.image {
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  max-width: 100vw;
  width: 80vw;
  /* height: 35vw; */
}

.description {
  font-weight: 300 !important;
  font-size: 16px;
  margin-top: 40px;
  color: rgba(255, 255, 255, 0.693);
}

.container {
  margin-top: 40px;
  /* padding: 0 80px 80px 80px; */
}

.body {
  background-color: #1b1c20;
  border-radius: 30px;
  margin-top: 6.5vh;
}

.details {
  color: rgba(255, 255, 255, 0.673);
}

