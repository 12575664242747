@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Poppins";
  src: url(../../fonts/Poppins-Bold.ttf);
  font-weight: 700;
}

* {
  font-family: "Poppins";
}

.title {
  font-size: 60px;
  text-align: left;
  color: white;
}

.container {
  border-radius: 10px;
  background-color: #131311;
}

option {
  text-align: center;
}
.navigationButtons {
  width: 80%;
  height: 40px;
  list-style: none;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 100px;
  padding-top: 40px;
}

.navigationButtons a {
  padding: 10px;
  margin: 8px;
  border-radius: 10px;
  border: 3px solid #3992ff;
  color: #3992ff;
  cursor: pointer;
  transition: all 0.5s;
}

.navigationButtons a:hover {
  color: white;
  background-color: #3992ff;
}

.navigationActive a {
  color: white;
  background-color: #3992ff;
}

.navigationDisabled a {
  display: none;
}
