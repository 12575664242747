.signInImage {
  background-image: linear-gradient(to bottom, #0dccff, #4760ff);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}
.IEEETextShadow {
  bottom: -9%;
  left: 8%;
  font-size: 10rem;
}
.myForm {
  background-color: "#F9F9F9" !important;
}
.authSection {
  background-color: #121316;
}
